import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Footer(){
    const location = useLocation();
    
    const [ isLanding, setIsLanding ] = useState(false);
    const [ isForm, setIsForm ] = useState(false);
    const [ isSplash, setIsSplash ] = useState(false);

    useEffect(()=>{
        const path = location.pathname;
        setIsSplash( path==='/' );
        setIsLanding( path==='/home' );
        setIsForm( path==='/form' );
    },[location])

    return (
        <footer className='pt-12 w-full flex flex-col justify-center'>
            <span className="block my-2 py-2 text-xs w-full max-w-sm md:max-w-xl mx-auto text-center">No purchase necessary. Must be 21 to sign up. Program is not open to residents of Quebec or New Brunswick. Restaurant gift card not eligible for residents of Alberta.<br/>

*Stats via Budweiser Friendship Survey, conducted by Ipsos (June 2023)<br/>

Enjoy responsibly. Must be legal drinking age. ®/MD Anheuser-Busch, LLC.
</span>
            <div className="footer-row bg-navy w-full p-8">
                <div className="flex gap-8 max-w-sm mx-auto text-center justify-center">
                    <Link className="font-bold font-body text-xs md:text-base" to="/terms">Terms of Use</Link>
                    <Link className="font-bold font-body text-xs md:text-base" to="/privacy-policy">Privacy Policy</Link>
                </div>
            </div>
        </footer>
    );
}