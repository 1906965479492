import { Component, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tween } from 'react-gsap';
import { gsap } from 'gsap';
import { IconContext } from 'react-icons';
import { FaArrowRight } from "react-icons/fa";
import BgDecoration from '../BgDecoration';
import BudsForm from '../BudsForm';
import InfoForm from '../InfoForm';
import ThankYou from '../ThankYou';
import '../../styles/Landing.scss';

import logo from '../../assets/images/bowtie.svg';
import btndec from '../../assets/images/arrow-down.png';
import video from '../../assets/video/main.mp4';

const ENV = process.env.ENV;
const COMM_API = 'community_api_9f53882903e1f61c1b4ad6e2c6cf7110a770475a'; 
const COMM_ID = '375e5da6-bb80-44d3-8070-836d63cf32cd';
const CORS_ID = 'temp_05187856a6bca9ec180366f297d237f1';

class Landing extends Component{

    state = {
        step: 1,
       frequency: '',
       province: '',
       age: '',
       optin: false,
       optin2: false,
       mo: '',
       da: '',
       ye: '',
       email: '',
       optin3: false,
       phone: '',
       frequencyError: '',
       provinceError: '',
       ageError: false,
       phoneError: '',
       optinError: false,
       optin2Error: false,
       moError: '',
       daError: '',
       yeError: '',
       emailError: '',
    }

    validateInfoForm = () => {
        const {province, optin, optin2, mo, da, ye, email } = this.state;

        let provinceError = "";
        let ageError = "";
        let daError = "";
        let moError = "";
        let yeError = "";
        let optinError = "";
        let optin2Error = "";
        let emailError = "";

        const bday = new Date(ye,mo-1,da);
        const diff = Date.now() - bday.getTime();
        const diffdate = new Date(diff);
        const age = Math.abs(diffdate.getUTCFullYear() - 1970);
        console.log( '?',bday, diff, diffdate, age );
        // todo. different age for quebec.

        province ? provinceError = "" : provinceError = "* Please select a province.";
        age >=19 ? ageError = "" : ageError = "* Sorry, you must be 21 or older to continue.";
        da ? daError = "" : daError = "* Sorry, you must be 21 or older to continue.";
        mo ? moError = "" : moError = "* Sorry, you must be 21 or older to continue.";
        ye ? yeError = "" : yeError = "* Sorry, you must be 21 or older to continue.";
        optin ? optinError = "" : optinError = "*Please confirm you agree to the Terms of Use and Privacy Policy.";
        optin ? optin2Error = "" : optin2Error = "*Please confirm you agree to receive updates, special offers, newsletters and other marketing messages from Budweiser Canada.";
        email ? emailError = "" : emailError = "*Please enter a valid email address.";

        if ( provinceError || optinError || optin2Error || daError || moError || yeError || ageError || emailError ) {
            this.setState({
                provinceError,
                optinError,
                optin2Error,
                daError,
                moError,
                yeError,
                ageError,
                emailError
            })
            return false;
        }

        return true;
    }

    validateBudsForm = () => {
        const { optin3, phone, frequency} = this.state;

        let phoneError = "";
        let optin3Error = "";
        let frequencyError = "";
       
        if (phone === "") {
            return true;
        }
        else if (phone != "") {
            optin3 ? optin3Error = "" : optin3Error = "* Please confirm you agree to receive updates, special offers, and other marketing messages from Budweiser Canada.";
            frequency ? frequencyError = "" : frequencyError = "* Please choose an option.";
            if (optin3Error || frequencyError) {
                this.setState({
                    optin3Error,
                    frequencyError
                })
                return false;
            }
        }

        return true;
    }

    handleSubmitStep1 = (e) => {
        
        e.preventDefault();
        const { step, province, age, da, mo, ye, optin, optin2, email } = this.state;
        const isValid= this.validateInfoForm();
        if (isValid === true){
            this.setState({ step: 2 })
            window.scrollTo(
                { 
                 top: 1000,
                 left: 0,
                 behavior: "smooth"
             });
        }
    }

    handleSubmitStep2 = (e) => {
        e.preventDefault();
        
        const { step, province, email, age, da, mo, ye, optin, optin2, optin3, frequency, phone } = this.state;

        const isValid= this.validateBudsForm(); 
        if (isValid === true){
            e.currentTarget.disabled = true;
            this.handleSubmit();
        }
    }

    handleSubmit = async e => {
        const { step, province, email, age, da, mo, ye, optin, optin2, optin3, frequency, phone  } = this.state;
            // SAVE CMD TO Community
            const cmd_options = {
                method: 'POST',
                headers: {'content-type': 'application/json' },
                body: JSON.stringify(this.state)
            };
            const cmd_status = await fetch(`https://thatswhatbudsdobe-stage.azurewebsites.net/signup`, cmd_options)
            .catch(err=>{
                console.log('error',err); // todo. what to do with error?
            });
            console.log('cmd',cmd_status);
            if (cmd_status.status!==202) console.log('error');

        
        // todo. scroll back to top of Thank you Message
        this.setState({ step: 3 });
        window.scrollTo(
           { 
            top: 1000,
            left: 0,
            behavior: "smooth"
        });
    }

    handleChange = input => e => {
        const errorState = e.target.name + 'Error';
        this.setState({
            [input] : e.target.value,
            [errorState]: ""
        })
    }

    handleCheckbox = e => {
        this.setState({
            [e.target.name] : e.target.checked
        })

        ////setting error to be blank once clicked
        const errorState = e.target.name + 'Error';
        this.setState({
            [errorState] : ""
        })
    }

    render() {

        const { step, loading } = this.state;
        const { frequency, province, age, da, mo, ye, phone, optin, optin2, optin3, rules, email } = this.state;
        const values =  { frequency, province, age, da, mo, ye, phone, optin, optin2, optin3, rules, email };
        const { frequencyError, phoneError, provinceError, rulesError, ageError, daError, moError, yeError, optinError, optin2Error, optin3Error, emailError } = this.state;
        const errorMessages = { frequencyError, phoneError, provinceError, rulesError, ageError, optinError, optin2Error, optin3Error, daError, moError, yeError, emailError };

        return(
            <Tween ƒƒ
            from={{ y:50, alpha:0, stagger:.2 }}
            to={{y:0, alpha:1, stagger:.2}}
            >
         
            <div className={step===1 ? 'landing step1' : step===2 ? 'landing step2' : 'landing thank-you'}>
                <section className='ml-12 mb-32 md:mb-0 sm:ml-20 md:ml-0 relative'>
                    <h1 className='text-3xl md:text-4xl normal-case w-full'>Friendship is in decline. 7 out of 10 men see their friends less in person as they age*. <br/>Budweiser is on a mission to change that.</h1>
                    <h2 className='normal-case text-xl md:text-2xl w-full lg:max-w-sm'>To start, we’re giving you a $25 Boston Pizza gift card to use towards your next get-together, and other amazing deals. Then, for when life gets in the way, we created a simple tool that sends text reminders, and maybe even rewards, to help keep you connected. No more rain checks on “beers tonight?” texts.</h2>
                    <h2 className='normal-case text-xl md:text-2xl w-full lg:max-w-sm'>Let’s make time for buds.</h2>
                    <p className='font-heading mt-0'>No purchase necessary. Must be 21 to sign up. Program is not open to residents of Quebec or New Brunswick. Restaurant gift card not eligible for residents of Alberta.</p>
                    <a className='get-started w-fit' href="#form">
                        <span>get started</span>
                        <img src={btndec} alt='"Budweiser presents: Bring Buds Back' />
                    </a> 
                </section>
                <section className='form-wrap'>
                    <img className='w-full max-w-[300px] md:mb-20 md:-ml-12' src={logo} alt='"Budweiser Bowtie' />
                    <div id="form">
                        {step === 1 ?  <InfoForm values={values} handleChange={this.handleChange} errorMessages={errorMessages}  validateInfoForm={this.validateInfoForm} handleSubmitStep1={this.handleSubmitStep1} handleCheckbox={this.handleCheckbox} /> 
                        : step === 2 ? <BudsForm values={values} errorMessages={errorMessages} validateBudsForm={this.validateBudsForm} handleSubmitStep2={this.handleSubmitStep2} handleChange={this.handleChange} handleSubmit={this.handleSubmit} handleCheckbox={this.handleCheckbox} /> 
                        : <ThankYou />
                        }
                    </div>
                </section>
            </div>
            </Tween>
        );
    }
}

export default Landing;