import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from "react-cookie-consent";
import BgDecoration from '../BgDecoration';
import '../../styles/Splash.scss';
import logo from '../../assets/images/bowtie.svg';


export default function Splash({updateAge}){
    const navigate = useNavigate();
    
    const [ errors, setErrors ] = useState({});

    function bouncer(){
        Cookies.set('ofage', true, {expires:1});
        updateAge(true);
        navigate('/home');
    }
    function bounceout(){
        Cookies.set('ofage',false, {expires:1});
        window.open('https://www.familytalkaboutdrinking.com/');
        setErrors({underage:true});
    }
    function unbounce(){
        Cookies.remove('ofage');
        setErrors({});
    }
    
    useEffect( ()=>{
        const ofage = Cookies.get('ofage');
        // console.log('ofage',ofage);
        if (ofage==='false'){
            console.log('go');
            setErrors({underage:true});
        } else if (ofage==='true'){
            navigate('/home');
        }
    },[navigate]);


    return (
        <>
            <img src={logo} className='w-fit mx-auto max-w-xxs' alt="Bring Buds Back" />
            <div className='age-gate'>
                {errors.underage 
                    ? 
                    <div className="age-gate-row mt-8 text-center">
                        <button className='w-fit mx-auto' onClick={unbounce}><span>Back</span></button>
                        <span className="error-text">* Sorry, you must be of legal drinking age to enter this site.</span>
                    </div>
                    : 
                    <>
                        <h3 className='text-center my-8 text-3xl font-normal normal-case'>Are you of legal drinking age?</h3>
                        <div className="age-gate-row flex justify-center gap-4">
                            <button onClick={bouncer}><span>YES</span></button>
                            <button onClick={bounceout}><span>NO</span></button>
                        </div>  
                    </>
                }
                <div>
                    <p className='mt-8 text-xs text-center w-fit mx-auto fixed bottom-8 left-0 right-0'>Enjoy responsibly. Must be legal drinking age. ​​®/MD Anheuser-Busch, LLC.</p>  
                </div>
            </div> 
        </>
    );
}