import React, {useEffect, useState} from 'react';
import { unstable_HistoryRouter as HistoryRouter, useLocation, Route, Routes, Link } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Splash from './components/pages/Splash';
import Landing from './components/pages/Landing';
import { gsap } from 'gsap';
import { Tween } from 'react-gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import CookieConsent from 'react-cookie-consent';
import { Cookies } from "react-cookie-consent";
import './App.scss';
import Footer from './components/Footer';
import Terms from './components/pages/Terms';
import Privacy from './components/pages/Privacy';
import BgDecoration from './components/BgDecoration';
import ScrollToTop from './components/ScrollToTop';
import video from '../src/assets/video/main.mp4';
import img1 from '../src/assets/images/portraits/buds-1.jpg';
import img2 from '../src/assets/images/portraits/buds-3.jpg';
import img3 from '../src/assets/images/portraits/buds-4.jpg';
import img4 from '../src/assets/images/portraits/buds-2.jpg';
import PromoOne from './components/pages/PromoOne';
import PromoTwo from './components/pages/PromoTwo';

const history = createBrowserHistory({window});
history.listen(({action,location}) => {
});

function App() {
  const [ ofAge, setOfAge ] = useState(Cookies.get('ofage')==='true');
  return (
    <>
      <HistoryRouter history={history}>
        <AppWrap>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Splash />} />
            <Route index element={<Splash updateAge={setOfAge} />} />
            <Route exact path="/home" element={ofAge?<Landing />:<Link to='/' />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/terms-promo1" element={<PromoOne />} />
            <Route exact path="/terms-promo2" element={<PromoTwo />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
          </Routes>
        </AppWrap>
    </HistoryRouter>
    </>
  );
}

function AppWrap (props) {
  const location = useLocation();
  const [ isSplash, setIsSplash ] = useState(false);
  const [ innerPage, setInnerPage ] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(()=>{
      const path = location.pathname;
      setIsSplash( path==='/' );
      setInnerPage( path==='/terms' || path==='/privacy-policy' || path==='/terms-promo1' || path==='/terms-promo2' );
      gsap.registerPlugin(ScrollTrigger);

      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
  },[location]);

  const isMobile = width <= 768;

  return (
    <Tween
      from={{ y:50, alpha:0, stagger:.2 }}
      to={{y:0, alpha:1, stagger:.2}}
    >
        <div className={`App ${isSplash?'splash':'' || innerPage?'inner-page':'' }`}>
        {isSplash && 
          <BgDecoration id='splash' />
        }
        {!isSplash && !innerPage && 
          <div className='flex float-right w-full pl-20 md:pl-0 md:max-w-xl md:mx-auto md:flex-none md:float-none h-48 md:h-60 md:h-80'>
            {isMobile ?
            <iframe className='w-full h-full' src="https://www.youtube.com/embed/DTMZKlkXP_k?mute=1&autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen poster="./poster.png" alt="A 3 minute film featuring real Canadian men in a local bar reflecting on their true friendships."></iframe>
            : <iframe className='w-full h-full' src="https://www.youtube.com/embed/DTMZKlkXP_k?mute=0&autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen poster="./poster.png" alt="A 3 minute film featuring real Canadian men in a local bar reflecting on their true friendships."></iframe>
            }
          </div>
        
        }
        <div className='container mx-auto pb-12 justify-center flex flex-col md:max-w-xl z-10 px-8 md:px-0 relative'>
          { props.children }
        </div>

        {!isSplash && !innerPage &&
          <>
            
              <section className='portrait-wrap'>
                <Tween 
                  to={{
                    y: -40,
                    duration: 2,
                    start: 'top center',
                    end: 'bottom top',
                    scrollTrigger: {
                    trigger: "#portrait1",
                    scrub: 0.5
                    }
                  }}
                >
                  <img className='portrait' id="portrait1" src={img1} alt="Two men outdoors, one older and one younger, standing side by side and smiling." />
                </Tween>
                <Tween 
                  to={{
                    y: -100,
                    duration: 2,
                    start: 'top center',
                    end: 'bottom top',
                    scrollTrigger: {
                    trigger: "#portrait2",
                    scrub: 0.5
                    }
                    }}
                >
                <img className='portrait' id="portrait2" src={img2} alt="Two men peering behind a wall. One is smiling, the other squints his eyes in a playful manner." />
                </Tween>
                <Tween 
                  to={{
                    y: -100,
                    duration: 2,
                    start: 'top center',
                    end: 'bottom top',
                    scrollTrigger: {
                    trigger: "#portrait3",
                    scrub: 0.5
                    }
                  }}
                >
                  <img className='portrait' id="portrait3" src={img4} alt="Two men sitting beside each other in puffy outerwear and ball caps, smiling toward just off camera." />
                </Tween>
                <Tween 
                  to={{
                    y: -100,
                    duration: 2,
                    start: '-600px center',
                    scrollTrigger: {
                    trigger: "#portrait4",
                    scrub: 0.5
                    }
                  }}
                >
                  <img className='portrait' id="portrait4" src={img3} alt="Three men laughing while standing on a city street in the daylight." /> 
                </Tween>
              </section>
            
          <BgDecoration id='landing1' />
          <BgDecoration id='landing2' />
        </>
        }
        {!isSplash &&
          <>
            <Footer />
          </>
        }

      </div>
    </Tween>
    
  )
}

export default App;
