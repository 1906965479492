import img from '../assets/images/TWBD.svg';

export default function BgDecoration(props) {
    return (
        // <div id={props.id} className='bg-decoration absolute rotate-90 w-fit h-fit text-3xl'>
        //     <div className='flex flex-row items-end font-bold font-heading'>
        //         <a href="/home" alt="Budweiser tagline “That’s What Buds Do”">
        //             <span className='uppercase inline'>that's what</span>
        //             <span className='font-script inline'>Buds</span>
        //             <span  className='uppercase inline'>do</span> 
        //         </a>   
        //     </div>
        // </div> 
        <div id={props.id} className='bg-decoration'>
            <div className='flex flex-row items-end font-bold font-heading'>
                <a href="/home" alt="Budweiser tagline “That’s What Buds Do”">
                    <img src={img} alt="Budweiser tagline “That’s What Buds Do”" />
                </a>   
            </div>
        </div>  
    ); 
}

