import React, { useState, useRef, useEffect, Component, Fragment } from 'react';
import { TextField, InputLabel, FormControl,  Select, MenuItem, FormHelperText } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { BsArrowRight, BsFillCaretDownFill } from "react-icons/bs";

export default function BudsForm({handleSubmitStep2,errorMessages,values,handleChange,handleCheckbox}){

    const [frequency, setFrequency] = useState('');
    const [phone, setPhone] = useState('');
    const [optin3, setOptin3] = useState('');
    const [frequencyError, setFrequencyError] = useState('');
    const [optin3Error, setOptin3Error] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [confirmed, setConfirmed] = useState();
    const finishBtn = useRef(null);

    return (
        
        <Fragment>
            <form className='buds' onSubmit={handleSubmitStep2}>
                <div>
                <span className='step'>step 2/2</span>
                </div>
                
                <h3 className='text-4xl font-bold w-full my-6 lg:max-w-xs'>cheers!</h3>
                <h4 className='normal-case text-xl md:text-2xl w-full lg:max-w-sm'>Our commitment to making time for buds doesn't end there. We created a text tool that sends you occasional reminders with the simple goal of keeping you connected.</h4>
                <div className='flex flex-col'>
                    <div className='my-4'>
                        <h4 className='font-heading capitalize text-2xl my-2'>your phone number</h4>
                        <FormControl fullWidth>
                            <TextField label="555-555-5555" variant="outlined" type="text" defaultValue={values.phone} onChange={handleChange('phone')} className={errorMessages.phoneError ? 'error' : ''} color='primary' name="phone" helperText={errorMessages.phoneError} />
                        </FormControl>
                    </div>
                    <h4 className='font-heading capitalize text-2xl my-2'>& how often would you like to stay in touch.</h4>
                    <FormControl className={errorMessages.frequencyError ? 'error' : ''}>
                        <InputLabel data-shrink="false" id="select-label">Frequency *</InputLabel>
                        <Select
                            labelId="select-label"
                            id="frequency-select"
                            defaultValue={values.frequency}
                            onChange={handleChange('frequency')}
                            name="frequency" 
                            IconComponent={() => (
                                <BsFillCaretDownFill />
                            )}
                        >
                            <MenuItem value={'twiceaweek'}>Twice a week</MenuItem>
                            <MenuItem value={'onceaweek'}>Once a week</MenuItem>
                            <MenuItem value={'frequency'}>Once every two weeks</MenuItem>
                        </Select>
                        <FormHelperText>{errorMessages.frequencyError}</FormHelperText>
                    </FormControl>
                    <div className='opt-in mt-8'>
                        <FormControl fullWidth className={errorMessages.optin3Error ? 'error' : ''}>
                            <Checkbox 
                                color="default" 
                                name="optin3" 
                                onClick={handleCheckbox} 
                                sx={{
                                    color: "#ffffff",
                                    '&.Mui-checked': {
                                        color: "#ffffff",
                                    },
                                }}
                            />
                            <p className='desc'>By providing my phone number, I agree that news & offers may be sent to me from Budweiser via Community at the phone number provided & agree to Community terms/privacy: <a href="http://community.com/terms" target="_blank" className="underline">community.com/terms.</a> Standard message frequency may vary. Standard msg. & data rates may apply. Reply HELP for help. Opt-out any time by replying STOP to cancel. *</p>
                        </FormControl>
                        <FormHelperText className={errorMessages.optin3Error ? 'error' : ''}>{errorMessages.optin3Error}</FormHelperText>
                    </div>
                </div>
                <button type="submit" onClick={handleSubmitStep2} ref={finishBtn}>
                    submit 
                </button>
               
            </form>
        </Fragment>
    );
}