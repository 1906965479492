import { useState, useRef, useEffect, Component, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ThankYou(){
    const navigate = useNavigate();
    const [frequency, setFrequency] = useState('');
    const [budsError, setBudsError] = useState('');
    const [landingActive, setLandingActive] = useState(); 
    const [ errors, setErrors ] = useState({});
    const submitBtn = useRef(null); 

    useEffect(() => {
        document.title = 'Bring Buds Back';
    }, []);

    return (
        <Fragment>
            <div className='min-h-[800px] md:min-h-[400px] h-full'>
                <h3 className='text-5xl font-bold w-full my-6 lg:max-w-xs'>Thank you</h3>
                <p className='font-heading text-2xl mb-0'>From after-work beers to game nights,<br/> let’s make more time for buds. <br/>That’s What Buds Do.</p>
                <p className='font-heading text-2xl mt-4'>Check your phone. <br /> We’ll take it from here.</p>
            </div>
        </Fragment>
    );
}