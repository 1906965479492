import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { TextField, FormControlLabel, InputLabel, FormControl, NativeSelect,  MenuItem, FormHelperText, Icon, createSvgIcon
 } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/system';
import { BsArrowRight, BsFillCaretDownFill } from "react-icons/bs";
import { IconContext } from 'react-icons';
import { FaArrowRight } from "react-icons/fa";


const monthOptions = Array.from(Array(12).keys()).map( m => ({ value:m+1, label:m+1}) );
const dayOptions = Array.from(Array(31).keys()).map( d => ({ value:d+1, label:d+1}) );
const yearOptions = Array.from(Array(100).keys()).map( a => ({ value:2005-a, label:2005-a}) );

const fields = [
    // const datefields = [
        {label:'MM',type:'number',id:'bdaymm',min:1,max:12,required:true},
        {label:'DD',type:'number',id:'bdaydd',min:1,max:31,required:true},
        {label:'YYYY',type:'number',id:'bdayyy',min:1900,max:2005,required:true},
    
    // optins
        {label:'I confirm I have read, understand, and agree to the <a href="/terms" target="_blank" rel="noreferrer">Terms of Use</a> and <a href="/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>.',type:'checkbox',id:'optin1',required:true,errorMessage:"Please confirm you agree to the Terms of Use and Privacy Policy."}
]

const provOptions = ["Ontario", "British Columbia", "Manitoba", "Alberta",  "Saskatchewan", "Nova Scotia", "Newfoundland and Labrador", "Prince Edward Island", "Yukon", "Nunavut", "Northwest Territories" ];


export default function InfoForm({handleSubmitStep1,errorMessages,values,handleChange,handleSubmit,handleCheckbox}) {

    const [ mo, setMo ] = useState();
    const [ da, setDa ] = useState();
    const [ ye, setYe ] = useState();
    const [ province, setProvince ] = useState('');
    const [ age, setAge ] = useState('');
    const [ optin, setOptin ] = useState('');
    const [ optin2, setOptin2 ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ moError, setMoError ] = useState('');
    const [ daError, setDaError ] = useState('');
    const [ yeError, setYeError ] = useState('');
    const [ provinceError, setProvinceError ] = useState('');
    const [ phoneError, setPhoneError ] = useState('');
    const [ ageError, setAgeError ] = useState('');
    const [ optinError, setOptinError ] = useState('');
    const [ optin2Error, setOptin2Error ] = useState('');
    const [ emailError, setEmailError ] = useState('');
    const [confirmed, setConfirmed] = useState();
    const submitBtn = useRef(null); 

    useEffect(() => {
    
        setConfirmed(0);
    },[confirmed]);


    return  (
        <Fragment>
            <form className='buds' onSubmit={handleSubmitStep1}>
                <div>
                    <span className='step'>step 1/2</span>
                    <span className='text-xs inline ml-3 uppercase'>* required fields</span>
                </div>    
                <div className='flex flex-col'>
                    <h3 className='text-5xl font-bold w-full my-6 lg:max-w-xs'>To sign up, enter the following:</h3>
                    <div>
                        <h4 className='font-heading capitalize text-2xl my-2'>your email</h4>
                        <FormControl fullWidth>
                            <TextField label="EMAIL" variant="outlined" required={true} type={"email"} defaultValue={values.email} onChange={handleChange('email')} className={errorMessages.emailError ? 'error' : ''} color='primary' name="email" helperText={errorMessages.emailError} />
                        </FormControl>
                    </div>
                    <div>
                    <h4 className='font-heading capitalize text-2xl my-2'>your province</h4>
                    <FormControl fullWidth className={errorMessages.provinceError ? 'error' : ''}>
                        <InputLabel id="province">province *</InputLabel>
                        <Select 
                            id="province-select"
                            labelId='province' 
                            defaultValue={values.province}
                            onChange={handleChange('province')}
                            name="province" 
                            options={provOptions} 
                            IconComponent={() => (
                                <BsFillCaretDownFill />
                            )}>
                            {provOptions.map(el=>(<MenuItem value={el}>{el}</MenuItem>))}
                            
                        </Select>
                        <FormHelperText>{errorMessages.provinceError}</FormHelperText>
                    </FormControl>
                    </div>
                    <h4 className='font-heading capitalize text-2xl my-2'>your birthday</h4>
                    <div className='flex gap-4 lg:gap-6 age-row'>
                        <FormControl fullWidth className={errorMessages.daError ? 'error' : ''}>
                            <InputLabel id="da">day *</InputLabel>
                        <Select labelId="da" required value={da} name="da" onChange={handleChange('da')} options={dayOptions}>
                            {dayOptions.map(el=>(<MenuItem value={el.value}>{el.value}</MenuItem>))}
                        </Select>
                        </FormControl>
                        <FormControl fullWidth className={errorMessages.moError ? 'error' : ''}>
                            <InputLabel id="mo">month *</InputLabel>
                            <Select labelId="mo" required value={mo} name="mo" onChange={handleChange('mo')} options={monthOptions}>
                            {monthOptions.map(el=>(<MenuItem value={el.value}>{el.value}</MenuItem>))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={errorMessages.yeError ? 'error' : ''}>
                            <InputLabel id="ye">year *</InputLabel>
                            <Select labelId="ye" required value={ye} name="ye" onChange={handleChange('ye')} options={yearOptions}>
                            {yearOptions.map(el=>(<MenuItem value={el.value}>{el.value}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </div>
                    <p className={errorMessages.daError ? 'age-error' : '' || errorMessages.moError ? 'age-error' : '' || errorMessages.yeError ? 'age-error' : '' || errorMessages.ageError ? 'age-error' : ''}>{
                    errorMessages.daError || errorMessages.moError || errorMessages.yeError || errorMessages.ageError }</p>
                    <div className='opt-in mt-8'>
                    <p className='desc font-bold mb-4'> Check both boxes to receive a $25 gift card to Boston Pizza.</p>
                        <FormControl fullWidth className={errorMessages.optinError ? 'error' : ''}>
                            <Checkbox
                            sx={{
                                color: "#ffffff",
                                '&.Mui-checked': {
                                    color: "#ffffff",
                                },
                            }}
                            color="default" name="optin" 
                            onClick={handleCheckbox} />
                            <p className='desc'>I confirm I have read, understand and agree to the <a href="/terms" target="_blank" className="underline">Terms of Use</a> and <a className="underline" target="_blank" href="/privacy-policy">Privacy Policy</a>. *</p>
                        </FormControl>
                        <FormHelperText className={errorMessages.optinError ? 'error' : ''}>{errorMessages.optinError}</FormHelperText>
                    </div>
                    <div className='opt-in' id="optin2">
                        <FormControl fullWidth required={true} className={errorMessages.optin2Error ? 'error' : ''}>
                            <Checkbox
                                sx={{
                                    color: "#ffffff",
                                    '&.Mui-checked': {
                                        color: "#ffffff",
                                    },
                                }}
                            color="default" name="optin2" onClick={handleCheckbox} />
                            <p className='desc'>By providing my email address and clicking submit, I consent to receive updates, special offers, newsletters, and other marketing messages from Budweiser and other trusted Labatt Brands. Contact information can be found in <a className="underline" target="_blank" href="/privacy-policy">Privacy Policy</a>. *</p>
                        </FormControl>
                        <FormHelperText className={errorMessages.optin2Error ? 'error' : ''}>{errorMessages.optin2Error}</FormHelperText>
                    </div>
                </div>
                <button onClick={handleSubmitStep1} type='submit' ref={submitBtn}>
                    Next
                </button>
            </form>
        </Fragment>
    );
}