import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from "react";

export default function PromoTwo(){
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'Bring Buds Back - Terms and Conditions: Boston Pizza';
    }, []);
    
    return (
        <div className="legal">
            <a className="py-2 px-8 button text-white" href="/home">home</a>
            <h1>Terms of Use: Boston Pizza</h1>
            <p>Thank you for visiting our website, mobile website, or mobile application (“app”) (collectively, the "Platform").<br />
Budweiser, Labatt Brewing Company Limited, Anheuser-Busch InBev and our subsidiary and affiliated entities, which include different brands, (“we”, “us” or “our”) want you to have the best possible experience when you interact with us online.
.</p>
            <p>These Terms of Use explain the terms and conditions that govern your use of our Platform.</p>
            <p>Along with these Terms of Use, our <a className="underline" href="https://www.budweiser.ca/ca_en/privacy-policy" target="_blank" alt="Privacy Policy">Privacy Policy</a> explains how we collect, use, disclose, process, transfer and protect information you may provide to us through your use of our Platform. You should make sure that you read and understand the Terms of Use and Privacy Policy because they create a binding contract between you and us.</p>
            <h2>CONSENT</h2>           
<p>YOUR USE OF THIS PLATFORM SIGNIFIES YOUR CONSENT TO BE LEGALLY BOUND BY BOTH THE TERMS OF USE AND THE PRIVACY POLICY. IF YOU DO NOT AGREE TO THE TERMS OF THE TERMS OF USE OR THE PRIVACY POLICY, DO NOT USE THIS PLATFORM OR PROVIDE ANY INFORMATION TO US.<br />
By clicking “I have read and I agree to the Terms of Use” in this Platform, you are providing your digital signature, confirming your agreement to these Terms of Use and our Privacy Policy and verifying that you are of legal drinking age.</p>
<h2>LEGAL DRINKING AGE NOTICE</h2>
<p>This Platform and any related content should not be accessed or viewed by you if you are not of legal drinking age in your country, province or territory. You confirm that you are legal drinking age and that you are not in a country, province or territory where the consumption of alcoholic beverages or the content of this Platform is not permitted.<br />
Eligibility for participation in contests, promotions and other aspects of this Platform may be restricted to Ontario residents or residents of other provinces.</p>
<h2>USER GENERATED CONTENT (UGC)</h2>
<p>This Platform may allow you to create or post user-generated content. We do not condone any user-generated content that promotes inappropriate or excessive alcohol consumption. Any content of this sort will be deleted without further notice to you. See the “Unlawful behaviour” section below for additional details.</p>
<h2>YOU CONFIRM YOU ARE LEGALLY PERMITTED TO USE THIS PLATFORM</h2>
<p>Your use of this Platform may not be available or permitted in all jurisdictions, and shall only constitute an "offer or solicitation" where permitted, and only to the extent permitted by law. This Platform shall not be used where or to any extent such use is prohibited by law. You agree and confirm that your use of this Platform is in full compliance with the laws of the jurisdiction(s) to which you are subject.</p>
<h2>REVISION OF THESE TERMS OF USE</h2>
<p>WE RESERVE THE RIGHT TO REVISE THESE CONTRACTUAL TERMS OF USE CONCERNING YOUR PRESENT AND FUTURE USE OF THIS PLATFORM AT ANY TIME AND FOR ANY REASON WITHOUT FURTHER NOTICE OR OBLIGATION TO YOU. IT IS YOUR SOLE RESPONSIBILITY TO ENSURE YOU AGREE AND COMPLY WITH THE TERMS OF USE AS POSTED AT ALL TIMES. IF YOU DO NOT AGREE OR DO NOT INTEND TO COMPLY WITH THE TERMS OF USE AS POSTED, YOUR SOLE REMEDY IS TO DISCONTINUE USING THIS PLATFORM IMMEDIATELY.</p>
<h2>USE OF THIS WEBSITE IS AT YOUR OWN RISK</h2>
<p>You acknowledge and agree that your use of this Platform is at your own risk. This means that you agree that we, including our owners, officers, directors, employees, or anyone involved in creating, producing, or delivering this website, are not liable for any damages whatsoever caused by your use of the Platform. This includes damages that are direct, incidental, consequential, indirect or special, or any other losses, costs or expenses of any kind (including legal fees, expert fees, or other disbursements) which may arise. You agree we are not liable regardless of whether such liability or damages arise in contract, tort, negligence, equity, statutorily or otherwise. You agree we are not liable for any such damages in connection with your access to, use of or browsing of this Platform or in connection with any content, information, data, promotions or activities associated with this Platform, or in connection with your downloading of any materials, text, data, images, video or audio from this Platform. You agree we are not liable for damages caused by transmission defects, viruses, data breach, data theft or loss, bugs, human action or inaction, or phone line, hardware, software or program malfunctions, or any other errors, failures or delays of any kind.</p>
<h2>COPYRIGHT AND ALL OTHER INTELLECTUAL PROPERTY BELONGS TO US</h2>
<p>We are the owners of copyright and all other intellectual property in this Platform. No portion of this Platform, including but not limited to the content, information, text, images, audio or video, may be used in any manner, or for any purpose, without our prior written permission.<br/>
Without in any way waiving any of the foregoing rights, you may download one copy of the material on this Platform for your personal, non-commercial home use only, provided you do not delete, obstruct or change any copyright, trademark or other proprietary notices.</p>

<p>You also have the limited ability to “share” content through a linked social media platform where such functionality is expressly provided by us in the Platform through a “share” button or menu item, provided, however, that you hereby acknowledge and agree that all “sharing” of content shall in no way limit our ownership of copyright and all other intellectual property in this Platform and/or effect the general limitations on your use of our content.</p>

<p>You hereby agree that modification, reposting or use of any content on this Platform in any other manner whatsoever and for any other purpose violates our legal and intellectual property rights.</p>
<h2>OUR NAME, LOGOS AND TRADEMARKS</h2>
<p>You acknowledge and agree that any name, logo, trademark or service mark contained on this Platform is owned or licensed by us (or a parent company, affiliate or partner of ours) and may not be used by you without prior written approval. We will enforce our intellectual property rights to the full extent of the law. Any unauthorized use of all, part, or any aspect of our intellectual property may subject you to penalties or damages, including but not limited to those related to violation of trademarks, copyright, privacy rights or publicity rights.</p>
<h2>THE INTERNET IS NOT A SECURE MEDIUM</h2>
<p>The internet is not a secure medium, and the privacy of your communications and/or visits to this Platform cannot be guaranteed. Your communications or information may be susceptible to data corruption, unauthorized access, data breach, theft or loss, interception and delays. This Platform may include incomplete information, inaccuracies or typographical errors. We may make changes in the information and content included in this Platform at any time without notice.</p>

<p>You acknowledge and agree that any communication or material you transmit to this Platform, in any manner and for any reason, may not be treated as confidential or proprietary. Furthermore, you acknowledge and agree that any communications, information, ideas, concepts, techniques, procedures, methods, systems, designs, plans, charts, or other materials you transmit through this Platform may be used by us anywhere, anytime and for any reason whatsoever.</p>
<h2>YOUR ACCOUNT</h2>
<p>To access and use our Platform, you may be required to create an account. You are responsible for maintaining the accuracy of account information, confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. We reserve the right, in its sole discretion, to refuse service, terminate accounts, remove or edit content, or cancel orders.</p>
<h2>WE HAVE THE RIGHT TO RESTRICT YOUR ACCESS TO THIS PLATFORM</h2>
<p>You agree that we have the right to prevent, intercept, delete, remove, prohibit or otherwise restrict your access to, use of, or posting of content on this Platform if we believe or determine that you have not fully complied or may not fully comply with any of the provisions of these Terms of Use or our Privacy Policy.</p>
<h2>UNLAWFUL BEHAVIOR</h2>
<p>You agree and confirm that you will not use this Platform to do anything that causes any harm or that is illegal or otherwise unlawful, including without limitation communicating or transmitting any hateful, harassing, pornographic, obscene, profane, defamatory, libelous or threatening material, or material which constitutes or may encourage conduct that would be considered a criminal offense, give rise to civil liability, promote the excessive, irresponsible or underage consumption of alcohol, or otherwise violate any law or regulation.</p>
<p>If this Platform contains integrated social media content, news feeds, bulletin boards, chat rooms, email, comment sections, or other message or communication functions (collectively, Forums), you agree to use the Forums only to send and receive material or post content that shall not, in any manner or to any extent, do any of the following:<br/>
commit any criminal or quasi-criminal offence including, without limitation, any pornography, hate, assault, or economic crime whatsoever;<br/>
defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others;<br/>
publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent or unlawful material or information;<br/>
infringe, contravene, breach or otherwise interfere with or harm the rights of any other person including, without limitation, any contractual, personality, confidentiality, privacy, moral, statutory, common law or intellectual property rights;<br/>
upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of a computer or mobile device;<br/>
delete any author attributions, trademarks, trade names, logos, legal notices or proprietary designations or labels in any file;<br/>
falsify the origin or source of software, information or other material;<br/>
advertise or offer to sell any goods or services, or conduct or forward any surveys, contests, or chain letters; and<br/>
download any file posted by another user that you know (or reasonably should know) cannot be legally distributed in such manner.</p>
<p>You acknowledge and agree that all Forums are public. You acknowledge that chats, comments, postings, conferences, social media integrations and other communications by other users are not monitored, screened, sanctioned, approved or endorsed by us, and shall not be considered as such.</p>

<h2>WE AND ALL OTHER PARTIES INVOLVED IN CREATING, PRODUCING OR DELIVERING THIS PLATFORM ASSUME NO RESPONSIBILITY OR LIABILITY ARISING FROM CLAIMS RELATED TO ANY OF THE ABOVE ACTIVITIES BY OTHER USERS.
HYPERLINKS</h2>
<p>Although this Platform may be linked to other websites, we are not directly or indirectly implying any approval, association, sponsorship, endorsement or affiliation with the linked website unless we specifically say so in writing. You acknowledge and agree that we have not reviewed linked websites and we are not responsible for the content of any website which is not hosted by us. Following links to other websites is at your own discretion and risk.</p>
<h2>NO WARRANTY ON CONTENTS, INFORMATION AND MATERIALS</h2>
<p>THE LAWS OF CERTAIN JURISDICTIONS (WHICH MAY INCLUDE THE PROVINCE OF QUEBEC) DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LEGAL WARRANTIES, CONDITIONS OR REPRESENTATIONS.  IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE BELOW EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
<p>Although the content in this Platform is based upon reasonably current information and we make reasonable efforts to ensure that it is correct, accuracy cannot be guaranteed.
For some information on this Platform, we rely on other entities to provide content without any direct or independent authentication, verification, assessment or validation by us. You are solely and exclusively responsible for evaluating all information for yourself. The information included in this Platform does not constitute, and shall not be considered, our advice, recommendation or endorsement.
THE INFORMATION AND CONTENT IN THIS PLATFORM IS PROVIDED ON AN "AS IS" BASIS ONLY, AND IS FOR CONSUMER PRIVATE AND NON-COMMERCIAL END USE ONLY. ALL EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS AND WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, COMPLETENESS, AUTHENTICITY, VALIDITY OR NON-INFRINGEMENT, ARE COMPLETELY DENIED AND DISCLAIMED, EXCEPT TO THE EXTENT THAT SUCH DISCLAIMERS ARE HELD TO BE LEGALLY INVALID BY A COURT OF COMPETENT JURISDICTION.</p>
<h2>EXCLUSIONS AND LIMITATIONS OF LIABILITY</h2>
<p>THE LAWS OF CERTAIN JURISDICTIONS (WHICH MAY INCLUDE THE PROVINCE OF QUEBEC), DO NOT ALLOW THE LIMITATION OF LIABILITY OR CERTAIN DAMAGES FOR CONSUMERS.  IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE BELOW LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS</p>
<p>You agree that we are not responsible for any incorrect or inaccurate information, whether caused by other users, or by the equipment or programming of this Platform, or by any technical or human error which may occur. You agree that we are not responsible for any potential impact of any of the above-noted circumstances upon your participation in contests or promotions through the Platform.
We are not responsible for any problems or technical malfunction of any telephone network or lines, wireless connections, mobile or cloud services, computer online systems, servers or providers, computer equipment, software, or failure of email or other communication, or any combination of these or other factors.</p>
<p>If, for any reason, this Platform is not capable of running as planned, including by reason of infection by computer virus, bugs, tampering, unauthorized intervention, data breach, fraud, technical failures, or any other causes which corrupt or affect the administration, security, fairness, integrity or proper conduct of this Platform, we reserve our rights to cancel, terminate, modify or suspend the app services or website indefinitely or take any other action we deem necessary to protect our interests.</p>
<h2>RELEASE AND INDEMNITY FOR CLAIMS</h2>
<p>YOU AGREE TO RELEASE, INDEMNIFY AND HOLD HARMLESS US AND OUR RESPECTIVE PARENTS, SUBSIDIARIES, AFFILIATES, DIRECTORS, SPONSORS, OFFICERS, CONSULTANTS, AGENTS, PARTNERS AND EMPLOYEES AGAINST AND FROM ALL CLAIMS, DAMAGES, LIABILITIES, COSTS AND EXPENSES ASSERTED BY YOU AND/OR ANY THIRD PARTIES ALLEGING THAT THE PLATFORM INCLUDES OBSCENITY, LIBEL OR SLANDER, OR INFRINGES UPON ANY INTELLECTUAL PROPERTY OR PRIVACY RIGHTS AS A RESULT OF THE USE OR MISUSE OF THE PLATFORM BY YOU AND/OR ANY THIRD PARTY.
YOU FURTHER CONFIRM THAT YOU ARE STRICTLY PROHIBITED FROM KNOWINGLY DAMAGING OR INTERRUPTING THE SERVICES ON THE PLATFORM, PREVENTING OTHERS FROM USING IT, OR OBTAINING OR MODIFYING ANOTHER USER'S INFORMATION WITHOUT CONSENT.</p>
<p>SHOULD A CLAIM BE BROUGHT AGAINST US INVOLVING ALLEGATIONS OF ANY SUCH ACTIVITY ON YOUR PART, YOU AGREE TO FULLY INDEMNIFY US FOR ANY AND ALL COSTS, AWARDS, PENALTIES OR DAMAGES RESULTING FROM THE CLAIM.</p>
<h2>APPLICABLE LAW, JURISDICTION AND DISPUTE RESOLUTION</h2>
<p>EXCEPT WHERE PROHIBITED BY APPLICABLE LAW (WHICH MAY INCLUDE THE PROVINCE OF QUEBEC), you acknowledge and agree that this Platform (including all transactions, contests, promotions or services offered or effected through it) and these contractual terms shall be construed and evaluated according to the laws of the Province of Ontario and the Federal laws of Canada applicable therein. You hereby agree and confirm that your use of this Platform and all of the communications, transmissions and transactions associated with it shall be deemed to occur in the Province of Ontario.</p>
<p>You hereby irrevocably submit to the exclusive jurisdiction of the courts of the Province of Ontario as the proper and most convenient forum for any dispute arising from your use of this Platform.
You acknowledge that any proceeding arising from your use of this Platform must be commenced in the City of Toronto in the Province of Ontario regardless of where you may be located and regardless of the application of any conflicts of law principles. If you use this website from another jurisdiction, you are responsible for your compliance with any and all applicable local laws.</p>
<h2>LANGUAGE</h2>
<p>English shall be the language of this Platform and all transactions occurring in connection with it. You waive any right to use and rely upon any other language or translations. Il est de la volonté express des parties que le présent Plateforme et tous les affairs qui s'y rapportent soient rédigés en langue anglaise, exception faite des documents pour lesquels la loi exige l'usage exclusif du français.</p>
<h2>MISCELLANEOUS</h2>
<p>You agree that if any part of these Terms of Use is determined to be invalid or unenforceable, the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Terms of Use shall continue in full force and effect.</p>

        </div>
    );
} 